
export default {
  name: "Philosophy",
  props: {
    image: String,
    stickerImage: String,
    stickerCaption: String,
  },
  methods: {
    resolve_url: function (path: string) {
      let images = require.context("../assets/", false, /\.jpg$|\.svg$/);
      return images("./" + path);
    },
  },
  setup() {
    return {};
  },
};
