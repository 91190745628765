import { Particle } from "./particle";
import WebFont from "webfontloader";

export class WriterSettings {
  startDelay = 0;
  duration = 50;
  text: Array<string> = [''];
  font = 'Arial';
  textSize = 80;
  easing = 'easeInOutCubic';  
  canvasWidth = 1248;
  canvasHeight = 300;
  lineHeight = 1;
}

export class Writer {
    ctx: any;
    tick = 0;
    particles: Array<Particle> = [];
    settings: WriterSettings;
    canvasWidth = 0;
    canvasHeight = 0;
    callback: any = null;


    constructor (canvasId: string, settings: WriterSettings, callback: any = null) {
      const canvas = document.getElementById(canvasId) as HTMLCanvasElement;
      this.callback = callback;
      this.settings = settings;
      this.canvasWidth = settings.canvasWidth;
      this.canvasHeight = settings.canvasHeight + this.settings.textSize;

      if (canvas) {
        this.ctx = canvas.getContext("2d");
        canvas.width = this.canvasWidth;
        canvas.height = this.canvasHeight;
        // A list of all the particles that forms the text 
        this.particles = [];
        this.tick = 0;
        WebFont.load({
            active: () => {
              this.init(settings.textSize)
          },
          custom: {
            families: [settings.font]
          }
        });
      }
    }
  
    init (size: number): void {
      this.ctx.fillStyle = "#FFFFFF"
      //this.ctx.shadowColor = "#445"
      //this.ctx.shadowOffsetX = 3;
      //this.ctx.shadowOffsetY = 5;
      //this.ctx.shadowBlur = 5;
      this.ctx.font = size +'px '+ this.settings.font.split(':')[0];
      // Draw text on the canvas temporarily

      this.settings.text.forEach( (row, index) => {
        const width = this.ctx.measureText(row).width;
        const textStartX = 10 + 0.5 * (this.canvasWidth - width);
        const hC = ( this.settings.canvasHeight - (this.settings.textSize * this.settings.lineHeight * this.settings.text.length)  ) * 0.2;
        const textStartY = hC + this.settings.textSize + 0.5 * (this.settings.lineHeight * this.settings.textSize - this.settings.textSize) + (index * this.settings.textSize * this.settings.lineHeight);
        this.ctx.fillText(row, textStartX, textStartY, this.canvasWidth);
      });

      const startX = -20;
      const startY = (this.canvasHeight - size) * 0.25;
      
      const image = this.ctx.getImageData(0, 0, this.canvasWidth, this.canvasHeight);
      const buffer32 = new Uint32Array(image.data.buffer);
      for(let x = 0; x < this.canvasWidth; x++) {
        for(let y = 0; y < this.canvasHeight; y++) {
          // The buffer is linear, y*w+x is a trick
          // to calculate the linear index.
          const color = buffer32[y * this.canvasWidth + x];
          if (color) {
            // There is a pixel here, add a particle
            this.particles.push(new Particle(
              startX + x, 
              startY + y, 
              Math.round(Math.random()*this.canvasWidth), 
              Math.round(Math.random()*this.canvasHeight), 
              color,
              this.settings,
              this
              ));
          }
        }
      }
      this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
    }
    
    draw(): void {
      // Start every frame with an empty image
      const imageData = this.ctx.createImageData(this.canvasWidth, this.canvasHeight);
      const pixels = new Uint32Array(imageData.data.buffer);
  
      this.particles.forEach((p) => {
        const x = Math.round(p.x);
        const y = Math.round(p.y);
        if(x >= 0 && x < this.canvasWidth && y >= 0 && y < this.canvasHeight) {
          pixels[x + this.canvasWidth*y] = p.color;
        }
        if(this.tick > this.settings.startDelay) {
          p.move(this.tick - this.settings.startDelay);
        }
      });
      
      this.ctx.putImageData(imageData, 0, 0);
      this.tick++;
      if (this.tick < 60) {
        requestAnimationFrame(() => this.draw());
      } else {
        if (null != this.callback) {
          setTimeout( () => { this.callback(); } , 3500 );
        }
      }
    }
  }