
import { defineComponent } from "vue";
import { SettingsService } from "../classes/SettingsService";

export default defineComponent({
  name: "Contacts",
  props: {
    arrow: Boolean
  },
  methods: {
    resolve_url: function (path: string) {
      let images = require.context("../assets/", false, /\.png$|\.svg$/);
      return images("./" + path);
    },
  },
  mounted() {
    const settingsInstance = SettingsService.Instance;
    settingsInstance.getSettings().then( s => {
      this.email = s.app_contacts_email;
      this.snVkontakte = s.social_vk;
      this.snTwitch  = s.social_twitch	;
      this.snDiscord = s.social_discord	;
      this.snYouTube = s.social_youtube;
      this.snTikTok = s.social_tiktok	;
      this.snIgmMobile = s.social_igmMobile;
    });
  },
  data() {
    return {
      email: '',
      snVkontakte: '',
      snYouTube: '',
      snTikTok: '',
      snTwitch: '',
      snDiscord: '',
      snIgmMobile: ''
    }
  },
  setup() {
    return {};
  },
});
