import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import NotFound from '../components/NotFound.vue'

const routes: Array<RouteRecordRaw> = [
  {
    meta: { breadCrumb: 'Главная' },
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    meta: { breadCrumb: 'Все кейсы' },
    path: '/case',
    name: 'Cases',
    component: () => import('../views/Cases.vue')
  },
  {
    meta: { breadCrumb: 'События' },
    path: '/events',
    name: 'Events',
    component: () => import('../views/Events.vue')
  },
  {
    meta: { breadCrumb: 'События' },
    path: '/event',
    name: 'Events',
    component: () => import('../views/Events.vue')
  },
  {
    meta: { breadCrumb: 'События' },
    path: '/event/:id',
    name: 'SingleEvent',
    component: () => import('../views/SingleEvent.vue')
  },
  {
    meta: { breadCrumb: 'Услуги' },
    path: '/service',
    name: 'Services',
    component: () => import('../views/Services.vue')
  },
  {
    meta: { breadCrumb: 'Кладбище мемов' },
    path: '/graveyard/:pageName',
    name: 'Oneyear',
    component: () => import('../views/Oneyear.vue')
  },
  {
    meta: { breadCrumb: 'Кладбище мемов' },
    path: '/graveyard',
    name: 'Graveyard',
    component: () => import('../views/Graveyard.vue')
  },
  {
    meta: { breadCrumb: 'Все кейсы' },
    path: '/case/:id',
    name: 'SingleCase',
    component: () => import('../views/SingleCase.vue')
  },
  {
    meta: { breadCrumb: 'Услуги' },
    path: '/service/:id',
    name: 'SingleService',
    component: () => import('../views/SingleService.vue')
  },
  {
    meta: { breadCrumb: 'Философия' },
    path: '/philosophy',
    name: 'Philosophy',
    component: () => import('../views/Philosophy.vue')
  },
  {
    meta: { breadCrumb: 'Контакты' },
    path: '/contacts',
    name: 'Contacts',
    component: () => import('../views/Contacts.vue')
  },
  {
    meta: { breadCrumb: 'Карта сайта' },
    path: '/sitemap',
    name: 'Sitemap',
    component: () => import('../views/Sitemap.vue')
  },
  {
    meta: { breadCrumb: 'Политика конфиденциальности' },
    path: '/policy',
    name: 'Policy',
    component: () => import('../views/Policy.vue')
  },
  {
    meta: { breadCrumb: '404' },
    path: "/:pathMatch(.*)*",
    name: "404",
    component: NotFound
  }
]

const router = createRouter({
//  scrollBehavior(to, from, savedPosition) {
//    return { top: 0 }
//  },  

  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash
        // , offset: { x: 0, y: 10 }
      }
    } else {
      return { top: 0 }
    }
  },
  

  history: createWebHistory(),
  routes
})

export default router
