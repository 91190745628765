
export default {
  name: "Case",
  props: {
    id: Number,
    topIcon: String,
    topIconCaption: String,
    bottomIcon: String,
    bottomIconCaption: String,
    header: String,
    subtitle: String,
    image: String,
    caseUrl: String,
    notes: Array,
    inlist: Boolean,
    isleft: Boolean,
    notilt: Boolean,
  },
  setup() {
    return {};
  },
};
