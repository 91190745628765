import { Settinngs } from "./settings";

export class SettingsService {
    private static _instance: SettingsService;

    private static settings: Settinngs | null | any = null;
    private static errorMessage = '';

    public static get Instance(): SettingsService {
        return this._instance || (this._instance = new this());
    }

    public getSettings(): Promise<Settinngs> {
        return new Promise<Settinngs>( (resolve, reject) => {
            if (null != SettingsService.settings) {
                resolve (SettingsService.settings);
            } else {
                fetch("/api.php?r=site/get-config").then( (response) => {
                    const data = response.json();
                    SettingsService.settings = data;
                    resolve(data);
                });
            }
        });
    }
}