
import { defineComponent } from "vue";

export default defineComponent({
  name: "App",
  data() {
    return {
      mShowMenu: false,
      scrollPosition: 0,
      windowHeight: window.innerHeight,
      mShowCrumbs: true,
      isServiceList: false
    };
  },

  methods: {
    resolve_url: function (path: string) {
      let images = require.context("./assets/", false, /\.png$|\.svg$/);
      return images("./" + path);
    },

    updateScroll: function() {
      this.scrollPosition = window.scrollY;
      this.scrollPosition += window.innerHeight > window.innerWidth ? window.innerHeight - window.innerWidth : 0;
      if (this.crumbs.length == 1 && this.crumbs[0].to == '/') {
        this.scrollPosition *= 0.90;
      }
    },

    showMenu(display: boolean) {
      if (display) {
        const position = window.scrollY;
        this.mShowMenu = display;
        document.body.style.position = 'fixed';
        document.body.style.top = `-${position}px`;
      } else {
        const scrollY = document.body.style.top;
        document.body.style.position = '';
        document.body.style.top = '';
        this.mShowMenu = display;

        window.scrollTo(0, parseInt(scrollY || '0') * -1);
      }
    },

    onResize: function() {
      this.windowHeight = window.innerHeight;
    },

  },
  setup() {
    return {};
  },
  computed: {
    crumbs: function () {
      let pathArray = [] as any;
      pathArray = this.$route.path.split("/");
      pathArray.shift();

      let breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
        breadcrumbArray.push({
          path: path,
          to: breadcrumbArray[idx - 1]
            ? "/" + breadcrumbArray[idx - 1].path + "/" + path
            : "/" + path,
          text:
            idx == pathArray.length - 1
              ? this.$route.params?.pageName ||
                this.$route.matched[idx]?.meta?.breadCrumb ||
                path
              : this.$route.matched[idx]?.meta?.breadCrumb || path,
        });
        return breadcrumbArray;
      }, []);
      return breadcrumbs;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll, {passive: false});
    window.addEventListener('resize', this.onResize, {passive: true});
  },
  updated() {
    if (
      this.crumbs[0].path == 'contacts' ||
      this.crumbs[0].path == 'sitemap'  ||
      this.crumbs[0].text == '404'
    ) {
      this.mShowCrumbs = false;
    }

    this.isServiceList = (this.crumbs[0].path == 'service');
  },
  destroy() {
    window.removeEventListener('scroll', this.updateScroll);
    window.removeEventListener('resize', this.onResize);
  }
});
