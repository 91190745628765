
export default {
  name: "Intro",
  props: {},
  methods: {
    resolve_url: function (path: string) {
      let images = require.context("../assets/", false, /\.png$|\.svg$/);
      return images("./" + path);
    },
  },
  setup() {
    return {};
  },
};
