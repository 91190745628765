
  import { defineComponent } from "vue";
  import { VueAgile } from "vue-agile";
  import { Writer } from "../classes/writer";
  import { SettingsService } from "../classes/SettingsService";
  import { Nebula } from "../classes/nebula";
  import Intro from "@/components/Intro.vue";
  import Case from "@/components/Case.vue";
  import Event from '@/components/Event.vue';
  import Philosophy from "@/components/Philosophy.vue";
  import Partners from "@/components/Partners.vue";
  import Contacts from "@/components/Contacts.vue";

export default defineComponent({
  name: "Home",
  components: {
    Intro,
    Case,
    Event,
    Philosophy,
    Partners,
    Contacts,
    VueAgile,
  },

  data() {
    return {
      nebula: {} as any,
      errorMessage: "",
      casesReady: false,
      caseList: [],
      eventsReady: false,
      eventList: [],
      inMove: false,
      activeSection: 0,
      offsets: [] as Array<number>,
      touchStartY: 0,
      currentY: 0,
      player: {} as any,
      showPlayButton: true,
      showVideo: false,
      showGalaxy: false,
      settings: {
        startDelay: 0,
        duration: 20,
        text: window.innerWidth > 599 ? ["IGM — ВАШ ПУТЬ", "ВО ВСЕЛЕННУЮ ВИДЕОИГР!"] : ["IGM — ВАШ ПУТЬ", "ВО ВСЕЛЕННУЮ", "ВИДЕОИГР!"],
        easing: "easeInOutCubic",
        font: 'Octo',
        lineHeight:   window.innerWidth > 1919 ? 1.5  : window.innerWidth > 1239 ? 1.4  : window.innerWidth > 1023 ? 1   : 1.1,
        textSize:     window.innerWidth > 1919 ? 70   : window.innerWidth > 1239 ? 60   : window.innerWidth > 1023 ? 50  : window.innerWidth > 899 ? 44  : window.innerWidth > 600 ? 30  : 26,
        canvasWidth:  window.innerWidth ,//window.innerWidth > 1919 ? 1248 : window.innerWidth > 1239 ? 1040 : window.innerWidth > 1023 ? 880 : window.innerWidth > 899 ? 780 : window.innerWidth > 600 ? 530 : 300,
        canvasHeight: window.innerHeight //window.innerWidth > 1919 ? 280  : window.innerWidth > 1239 ? 158  : window.innerWidth > 1023 ? 116 : window.innerWidth > 899 ? 103 : window.innerWidth > 600 ? 72  : 100,
      },
      videoUrl: "",
      style: {
        width: window.innerWidth + "px",
        height: window.innerHeight + "px",
      },
      isFirefox: navigator.userAgent.toLowerCase().indexOf('firefox') > -1
    };
  },

  methods: {
    calculateSectionOffsets() {
      this.offsets = [];
      let sections = document.getElementsByTagName('section');
      let length = sections.length;

      for(let i = 0; i < length; i++) {
        let sectionOffset = sections[i].offsetTop;
        this.offsets.push(sectionOffset);
      }
    },
    handleMouseWheel: function(e) {
      e.stopPropagation();
      e.preventDefault();

      if (!this.inMove) {
        this.inMove = true;

        if (e.wheelDelta > 0) {
          this.moveUp();
        } else if (e.wheelDelta < 0) {
          if (this.activeSection < this.offsets.length - 1) {
            this.moveDown();
          } else {
            this.scrollToBottom();
          }
        }

      }
      return false;
    },
    moveUp() {
      if (this.activeSection > 0) {
        this.activeSection--;
        if (0 == this.activeSection) {
          const hideCookie = this.getCookie('hideintro');
          if (!hideCookie || 'true' != hideCookie) {
            const nebula = new Nebula();
            nebula.runNebula();
            this.nebula = nebula;
          }
        }
        this.scrollToSection(this.activeSection);
      } else {
        this.inMove = false;
      }
    },
    moveDown() {
      if(this.activeSection < this.offsets.length - 1) {
        if (0 == this.activeSection && null != this.nebula && this.nebula.destroy) {
          this.nebula.destroy();
          this.nebula = null;
        }
        this.activeSection++;
        this.scrollToSection(this.activeSection);
      }
    },
    scrollToSection(id) {
      const section = document.getElementsByTagName('section')[id];
      if (section) {
        section.scrollIntoView(this.isFirefox ? true : {behavior: 'smooth'});
      }
      
      
      setTimeout(() => {
        this.inMove = false;
      }, 400);
    },
    scrollToBottom() {
      document.getElementsByTagName('section')[this.activeSection].scrollIntoView(this.isFirefox ? false : {behavior: 'smooth', block: "end", inline: "nearest"});
      setTimeout(() => {
        this.inMove = false;
      }, 400);
    },
    touchStart(e) {
      this.touchStartY = e.touches[0].clientY;
      this.currentY = this.touchStartY;
    },
    touchMove(e) {
      e.preventDefault();
      e.stopPropagation();
      const currentY = e.touches[0].clientY;
      this.currentY = currentY;
    },
    touchEnd(e) {
      if(this.inMove) return false;
      const difference = Math.abs(this.touchStartY - this.currentY);

      if (difference > 140) {
        e.preventDefault();
        e.stopPropagation();

        if(this.touchStartY < this.currentY ) {
          this.moveUp();
        } else {
          this.moveDown();
        }

        this.touchStartY = 0;
        this.currentY = 0;
        return false;
      }
    },
    play() {
      const player = document.getElementById('ivideo');
      if (player) {
        (player as HTMLVideoElement).play();
      }
    },
    getCookie(name) {
      // eslint-disable-next-line no-useless-escape
      let matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"));
      return matches ? decodeURIComponent(matches[1]) : undefined;
    },
    showGalaxyAnimation() {
      this.showVideo = false;

      const hideCookie = this.getCookie('hideintro');
      if (hideCookie && 'true' == hideCookie) {
        return;
      }

      this.showGalaxy = true;
      this.calculateSectionOffsets();
      const nebula = new Nebula();

      setTimeout( () => {
        this.nebula = nebula;
        nebula.runNebula();
        window.addEventListener('resize', () => { nebula.onResize() } , false);
      }, 50);

      setTimeout(()=> {
        var writer = new Writer("canvas", this.settings, () => {
          document.cookie = "hideintro=true";
          window.onbeforeunload = function(e) { document.cookie = 'hideintro='; };
          this.calculateSectionOffsets();
          this.moveDown();
          setTimeout( () => {
            this.showGalaxy = false;
            this.calculateSectionOffsets();
            this.moveUp();
          }, 250);
        });
        writer.draw();
      }, 5300);

    },
    skip() {
      this.player.pause();
      localStorage.setItem('skipVideo', 'true');
      this.showGalaxyAnimation();
    },
    onStateChange(event: any) {
      this.showPlayButton = event.data != 1;
      if (0 == event.data) {
        localStorage.setItem('skipVideo', 'true');
        this.showGalaxyAnimation();
      }
    },
    getWidth() {
      return window.innerWidth;
    },
    checkIfSkipVideo() {
      const skipVideo = localStorage.getItem('skipVideo');
      if (skipVideo && 'true' == skipVideo) {
        this.showGalaxyAnimation();
      } else {
        this.showVideo = true;
        setTimeout( ()=> {
          this.setupVideo();
        }, 50);
      }
    },
    setupVideo() {
      const player = document.getElementById('ivideo');
      if (player) {
        player.addEventListener("contextmenu", function (e) { e.preventDefault(); e.stopPropagation(); }, false);
        player.addEventListener("ended", (e) => { e.preventDefault(); e.stopPropagation(); this.skip(); }, false);
        player.addEventListener('pause', (e) => { e.preventDefault(); e.stopPropagation(); this.showPlayButton = true; }, false);
        player.addEventListener('play',  (e) => { e.preventDefault(); e.stopPropagation(); this.showPlayButton = false; }, false);

        // hide the controls if they're visible
        if (player.hasAttribute("controls")) {
          player.removeAttribute("controls");
        }
        this.player = player;

        //Setup play button for Safari
        const ua = window.navigator.userAgent;
        const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
        const webkit = !!ua.match(/WebKit/i);
        const isSafari = iOS && webkit && !ua.match(/CriOS/i);

        if (isSafari) {
          this.showPlayButton = false;
          player.setAttribute("controls", "true");
        }
      }
    }
  },


  created() {
    // GET request using fetch with error handling
    fetch("/api.php?r=project/get-list&pageSize=30")
      .then(async (response) => {
        const data = await response.json();

        // check for error response
        if (!response.ok) {
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
        }

        this.caseList = data.items;
        this.casesReady = true;
      })
      .catch((error) => {
        this.errorMessage = error;
      });

    fetch("/api.php?r=event/get-list&pageSize=30")
      .then(async (response) => {
        const data = await response.json();

        if (!response.ok) {
          const error = (data && data.message) || response.statusText;
          return Promise.reject(error);
        }

        this.eventList = data.items;
        this.eventsReady = true;
      })
      .catch((error) => {
        this.errorMessage = error;
        console.error("Error fetching events: ", error);
      });
    
  },
  updated() {
    this.calculateSectionOffsets();
  },
  mounted() {
    window.addEventListener('wheel', this.handleMouseWheel, { passive: false });
    window.addEventListener('touchstart', this.touchStart, { passive: false });
    window.addEventListener('touchmove', this.touchMove, { passive: false });
    window.addEventListener('touchend', this.touchEnd, { passive: false });
    const settingsInstance = SettingsService.Instance;
    settingsInstance.getSettings().then( s => {
      this.videoUrl = s.introVideoUrl;
      this.checkIfSkipVideo();
    });
  },
  unmounted() {
    window.removeEventListener('wheel', this.handleMouseWheel, false);
    window.removeEventListener('touchstart', this.touchStart);
    window.removeEventListener('touchmove', this.touchMove);
    window.removeEventListener('touchend', this.touchEnd);
  }
});
