import { WriterSettings } from "./writer";

export class Particle {
    x: number;
    y: number;
    destX: number;
    destY: number;
    startX: number;
    startY: number;
    color: any;
    writer: any;
    settings: WriterSettings;

    constructor (destX, destY, x, y, color, settings, writer) {
      this.destX = destX;
      this.destY = destY;
      this.x = x;
      this.y = y;
      this.startX = x;
      this.startY = y;
      this.color = color;
      this.settings = settings;
      this.writer = writer;
    }
  
    // Based on Robert Penner's easing functions
    easeInOutCubic (t: number, b: number, c: number, d: number): number {
		if ((t/=d/2) < 1) return c/2*t*t*t + b;
		return c/2*((t-=2)*t*t + 2) + b;
	}

    easeOutCubic (t: number, b: number, c: number, d: number): number {
		return c*((t=t/d-1)*t*t + 1) + b;
	}
    easeOutBack (t: number, b: number, c: number, d: number): number {
        const s = 1.70158;
        return c*((t=t/d-1)*t*((s+1)*t + s) + 1) + b;
    }

    easeOutBounce (t: number, b: number, c: number, d: number): number {
        if ((t/=d) < (1/2.75)) {
            return c*(7.5625*t*t) + b;
        } else if (t < (2/2.75)) {
            return c*(7.5625*(t-=(1.5/2.75))*t + .75) + b;
        } else if (t < (2.5/2.75)) {
            return c*(7.5625*(t-=(2.25/2.75))*t + .9375) + b;
        } else {
            return c*(7.5625*(t-=(2.625/2.75))*t + .984375) + b;
        }
	}
  
    move (tick) {
        if(this.x !== this.destX) {
            this.x = this[this.settings.easing](
            tick, 
            this.startX, 
            this.destX - this.startX, 
            this.settings.duration);
        }

        if(this.y !== this.destY) {
            this.y = this[this.settings.easing](
            tick, 
            this.startY, 
            this.destY - this.startY, 
            this.settings.duration);
        }
    }
}