
export default {
  name: "Event",
  props: {
    header: String,
    image: String,
    eventUrl: String,
    format: String,
    date: String,
    note: String,
    inlist: Boolean,
    isleft: Boolean,
  },
  setup() {
    return {};
  },
};
