import * as THREE from 'three';

export class Nebula {
    camera: any;
    scene: any;
    renderer: any;
    uniforms: any;
    clock: any;
    animationId: any;
    container: any;


    glsl = x => x[0].trim();
    vert = this.glsl`
    varying vec2 vUv;
    void main()	{
      vUv = uv;
      gl_Position = vec4( position, 1.0 );
    }
    `;
    
    frag = this.glsl`
    precision highp float;
    uniform float time;
    uniform vec2 resolution;
    varying vec2 vUv;
    
    #define PI 3.141592654
    
    float rand(vec2 c){
        return fract(sin(dot(c.xy ,vec2(12.9898,78.233))) * 43758.5453);
    }
    
    float noise(vec2 p, float freq ){
        float unit = resolution.x/freq;
        vec2 ij = floor(p/unit);
        vec2 xy = mod(p,unit)/unit;
        //xy = 3.*xy*xy-2.*xy*xy*xy;
        xy = .5*(1.-cos(PI*xy));
        float a = rand((ij+vec2(0.,0.)));
        float b = rand((ij+vec2(1.,0.)));
        float c = rand((ij+vec2(0.,1.)));
        float d = rand((ij+vec2(1.,1.)));
        float x1 = mix(a, b, xy.x);
        float x2 = mix(c, d, xy.x);
        return mix(x1, x2, xy.y);
    }
    
    float pNoise(vec2 p, int res){
        float persistance = .5;
        float n = 0.;
        float normK = 0.;
        float f = 4.;
        float amp = 1.;
        int iCount = 0;
        for (int i = 0; i<50; i++){
            n+=amp*noise(p, f);
            f*=2.;
            normK+=amp;
            amp*=persistance;
            if (iCount == res) break;
            iCount++;
        }
        float nf = n/normK;
        return nf*nf*nf*nf;
    }
    
    // by IQ
    // cosine based palette, 4 vec3 params
    vec3 pal( in float t, in vec3 a, in vec3 b, in vec3 c, in vec3 d )
    {
      return a + b*cos( 6.28318*(c*t+d) );
    }
    
    // by IQ
    vec3 palette(float t) {
      float i1 = sin(t * .5);
      float i2 = sin(t * .3);
      vec3 a = vec3(0.3, 0.4, .5);
      vec3 b = vec3(0.5, .5, .5);
      vec3 c = vec3(1., 1.0, 1.0);
      vec3 d = vec3(0.0, 0.10, 0.20);
      return pal(t, a, b, c, d);
    }
    
    
    void main() {
      
      float vmax = max(resolution.y, resolution.x);
      float vmin = min(resolution.y, resolution.x);
      float z = 2.3;
      float aR = vmin / vmax; 
      vec2 p0 = z * aR * (vUv * 1. - .5); 
    
      vec2 p1 = p0 * (.5 + .5 * sin(aR * 6. * p0.x + time * .1) * cos(5. * p0.y + time * .2));  
      float a = atan(p1.y, p1.x);
      float l = mix(length(p0), length(p1), .1);
    
      vec2 p = vec2(
                cos(a + time * .05) * 1e3 * log(l), 
                sin(a + time * .05) * 1e3 * log(l));
      float pn = pNoise(p, 5);
      float n = .5 + 1. * pn;
      gl_FragColor = vec4(palette(l * .8 + time *.10 + n), 1.);
    }
    `;
    
    
    runNebula(): void {
        this.init();
        this.animate();
    }
    
    init(): void {
        this.container = document.querySelector('#nebula');
        if (this.container) {
            this.clock = new THREE.Clock();
            this.camera = new THREE.OrthographicCamera(-1, 1, 1, -1, 0, 1);
            this.scene = new THREE.Scene();
        
            const geometry = new THREE.PlaneBufferGeometry(2, 2);
        
            this.uniforms = {
              time: { type: 'f', value: 1.0 },
              resolution: { type: 'v2', value: new THREE.Vector2() },
            };
        
            const material = new THREE.ShaderMaterial({
              uniforms: this.uniforms,
              vertexShader: this.vert,
              fragmentShader: this.frag,
            });

            const mesh = new THREE.Mesh(geometry, material);
            this.scene.add(mesh);

            this.renderer = new THREE.WebGLRenderer();
            this.renderer.setPixelRatio(window.devicePixelRatio);
            this.container.appendChild(this.renderer.domElement);

            this.onResize();
        }
    }

    onResize(): void {
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.uniforms.resolution.value.x = this.renderer.domElement.width;
        this.uniforms.resolution.value.y = this.renderer.domElement.height;
    }

    animate(): void {
        this.animationId = requestAnimationFrame( () => { this.animate() });
        this.uniforms.time.value = this.clock.getElapsedTime();
        this.renderer.render(this.scene, this.camera);
    }

    destroy() : void {
        cancelAnimationFrame(this.animationId);// Stop the animation
        this.renderer = null;
        this.scene = null;
        this.camera = null;
        this.uniforms = null;
        while (this.container.lastChild) {
          this.container.removeChild(this.container.lastChild);
        }
    }
}